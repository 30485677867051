import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";
import { faAlignJustify, faDroplet } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import SectionC from "./sectionC";
import SectionD from "./sectionD";
import SectionB from "./sectionB";
import img from "../../img/hydrationfirst.jpg";
import Aos from "aos";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
export default function Hydration() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const handleMouseOver1 = () => {
    setIsHovered1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovered1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovered2(true);
  };
  const handleMouseOut2 = () => {
    setIsHovered2(false);
  };
  const Style = () => {
    return {
      fontSize: "xx-large",
      color: "black",
      fontWeight: "600",
      // textAlign: "left",
      marginBottom: "20px",
      display: "flex",
      // justifyContent:"space-between"
    };
  };
  const BoxStyle = () => {
    return {
      border: "1px solid #e6e600",
      padding: "4px 8px",
      borderRadius: "14px",
      fontSize: "20px",
      fontWeight: "500",
      color: isHovered ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered ? "#e6e600" : "transparent",
    };
  };
  const BoxStyle1 = () => {
    return {
      border: "1px solid #e6e600",
      padding: "4px 8px",
      borderRadius: "14px",
      fontSize: "20px",
      fontWeight: "500",
      transition: "background-color 0.4s",
      color: isHovered1 ? "white" : "black",
      backgroundColor: isHovered1 ? "#e6e600" : "transparent",
    };
  };
  const BoxStyle2 = () => {
    useEffect(() => {
      Aos.init({ duration: 1500 });
    }, []);
    return {
      border: "1px solid #e6e600",
      padding: "4px 8px",
      borderRadius: "14px",
      // fontSize: '20px',
      fontWeight: "500",
      color: isHovered2 ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered2 ? "#e6e600" : "transparent",
      fontSize: isHovered2 ? "22px" : "20px",
    };
  };
  return (
    <>
      <div className="w-100">
        <Row className="container">
          <Col xs={24} sm={24} md={24} lg={18} xl={16} xxl={24}>
            <Row
              gutter={10}
              style={Style()}
              className="justify-content-between h-75"
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                style={{
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                  position:"relative"
                }}
                className="d-flex align-items-center h-75"
              >
                <div
                  style={{ textAlign: "left",width:'100%' }}
                  data-aos="fade-right"
                ><h1 className="start-text">
                  Unlock Your Peak Performance,Get Real Time
                  <a style={{ color: " #0099ff" }}> Hydration</a>
                
                    <span style={{ display:'flex'}}>Insights Now
                    <Loader
                        type="bubble-top"
                        bgColor={"black"}
                        color={"black"}
                        size={30}
                       
                      />
                    </span>

                    </h1> 
                 
                </div>
              </Col>
              {/* <Col xs={8} sm={8} md={10} style={{}} data-aos="fade-left">
                <img
                  src={img}
                  alt="image"
                  style={{
                    width: "80%",
                    borderRadius: "50px",
                    padding: "2px",
                    height:"50%",
                    position:"absolute"
                  }}
                /> */}
                {/* ;
                <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="green"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                /> */}
              {/* </Col> */}
            </Row>

            {/* <p style={{marginTop:"10px"}}>
                Unlock Your Peak Performance,
                Get Real Time <span style={{ margin: '0px 4px 0px 4px', color: ' #0099ff' }}>Hydration</span> Insights
                <p style={{ display: 'flex', marginTop: '0px' }}>Now <Loader type="bubble-top" bgColor={'black'} color={'black'} size={70} /></p>
              </p> */}
          </Col>
        </Row>
        <Row gutter={10} className="container">
          <Col
            style={{ marginBottom: "10px" }}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={4}
          >
            <div
              style={BoxStyle()}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px" }}
              />
              Drink Pently Of Water
            </div>
          </Col>
          <Col
            style={{ marginBottom: "10px" }}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={4}
          >
            <div
              style={BoxStyle1()}
              onMouseOver={handleMouseOver1}
              onMouseOut={handleMouseOut1}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px" }}
              />
              Avoid Alcohol
            </div>
          </Col>
          <Col
            style={{ marginBottom: "10px" }}
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={4}
          >
            <div
              style={BoxStyle2()}
              onMouseOver={handleMouseOver2}
              onMouseOut={handleMouseOut2}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px" }}
              />
              Eat Healthy Food
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex" }} className="container">
          <div style={{ display: "flex" }}>
            <div>
              <p
                style={{
                  color: "blue",
                  fontSize: "x-large",
                  marginTop: "50px",
                }}
              >
                Scroll Down
              </p>
            </div>
            <div
              class="scroll-down-dude"
              style={{ marginTop: "50px", marginLeft: "15px" }}
            ></div>
          </div>
        </div>
        <section className="container">
          <SectionB></SectionB>
          <SectionC></SectionC>
          <SectionD></SectionD>
        </section>
        <section></section>
      </div>
    </>
  );
}
