import React, { useState } from 'react';
import { Col, Divider, Menu, Row } from 'antd';
import img from '../lidsense.png';
const items = [
  {
    label: (
      <a href="/hydration">
        <img src="./favicon.ico" alt="logo" />
      </a>
    ),
    key: 'logo',
  },
  {
    label: (
      <a href="/hydration">
        Hydration
      </a>
    ),
    key: 'hydration',
  },
  {
    label: (
      <a href="/Error">
        Glucose
      </a>
    ),
    key: 'glucose',
  },
];
const NavBar = () => {
  const [current, setCurrent] = useState('logo');

  const onClick = (e) => {
    setCurrent(e.key);
  };
  return (
    <>
      <Row gutter={16} className='container'>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
          <img src={img} alt="logo" style={{ width: "130px", marginLeft: "10px" }} />
        </Col>
        <Col xs={12} sm={12} md={14} lg={20} >
          <Menu style={{ border: 'none', fontSize: "20px", fontWeight: "500px", justifyContent: "end" }} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items.slice(1)} />
        </Col>
      </Row>
      <Divider className='mb-0 p-0'></Divider>
    </>
  );
};
export default NavBar;
