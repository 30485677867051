import { Col, Image, Row } from 'antd';
import React,{useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
const PartC=(props)=> {
  useEffect(() => {
    Aos.init();
  }, [])
  return (
    <>
     {/* <section className='container'> */}
      <Row gutter={30} style={{justifyContent:'center',margin:"20px 0px"}}>
        <Col xs={24}sm={24} md={12} lg={12} xl={10} xxl={10}data-aos="fade-right">
          <h2>{props.a}</h2>
          <Image src={props.img} />
        </Col>
        <Col  xs={24}sm={24} md={12} lg={12} xl={14} xxl={14}data-aos="fade-left">
          <div>
            <p className='why-importance'>
            {props.b}
            </p>
          </div>
          <div style={{marginTop:'45px',textAlign:"left",fontSize:"15px", }}>
            <p ><FontAwesomeIcon icon={faCircleCheck} style={{color:'green',backgroundColor:'white',marginRight:'5px'}}/>{props.c}</p>
            <p><FontAwesomeIcon icon={faCircleCheck} style={{color:'green',backgroundColor:'white',marginRight:'5px'}}/>{props.d}</p>
            <p><FontAwesomeIcon icon={faCircleCheck} style={{color:'green',backgroundColor:'white',marginRight:'5px'}}/>{props.e}</p>
          </div>
        </Col>
      </Row>
      {/* </section> */}
    </>
  );
}
export default PartC;


