import React, { useState, useEffect } from "react";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "antd";
import { LuWineOff } from "react-icons/lu";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { MdNoFood } from "react-icons/md";
import Aos from 'aos';
// import './partd.css'

const PartD = (props) => {
  useEffect(() => {
    Aos.init();
  }, [])
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const handleMouseOver1 = () => {
    setIsHovered1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovered1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovered2(true);
  };
  const handleMouseOut2 = () => {
    setIsHovered2(false);
  };
  const BoxStyle = () => {
    return {
      borderColor: " #1a75ff",
      borderRadius: "15px",
      color: isHovered ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered ? " #1a75ff" : "transparent",
    };
  };
  const BoxStyle1 = () => {
    return {
      borderColor: " #1a75ff",
      borderRadius: "15px",
      color: isHovered1 ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered1 ? " #1a75ff" : "transparent",
      paddingBottom:"32px"
    };
  };
  const BoxStyle2 = () => {
    return {
      borderColor: " #1a75ff",
      borderRadius: "15px",
      color: isHovered2 ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered2 ? " #1a75ff" : "transparent",
      paddingBottom:"12px"
    };
  };
  return (
    <>
    {/* <section className='container'> */}
      <h2>{props.a}</h2>
     
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}} data-aos="fade-right">
          {/* <ScrollAnimation animationIn="bounceInRight"> */}
            <Card
              style={BoxStyle()}
              className={isHovered ? "show-card" : "hide-card"}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px", color: "#1a75ff" }}
              />
              <h3>{props.b}</h3>
              <p style={{ fontSize: "medium", marginBottom:10 }}>{props.c}</p>
              <p style={{marginTop:0}}>{props.d}</p>
            </Card>
            {/* </ScrollAnimation> */}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}data-aos="fade-left">
          <AnimatedOnScroll animationIn="bounceInRight" duration="10000">
                
            <Card
              style={BoxStyle1()}
              className={isHovered1 ? "show-card" : "hide-card" }
              onMouseOver={handleMouseOver1}
              onMouseOut={handleMouseOut1}
            >
              <LuWineOff 
                style={{ marginRight: "10px", color: "#1a75ff" }}
              />
              <h3>{props.e}</h3>
              <p style={{ fontSize: "medium" }}>{props.f}</p>
              <p>{props.g}</p>
            </Card>
            </AnimatedOnScroll>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}data-aos="fade-left">
            <Card
              style={BoxStyle2()}
              className={isHovered2 ? "show-card" : "hide-card"}
              onMouseOver={handleMouseOver2}
              onMouseOut={handleMouseOut2}
            >
            <MdNoFood 
                style={{ marginRight: "10px", color: "#1a75ff" }}
              />
              <h3>{props.h}</h3>
              <p style={{ fontSize: "medium" }}>{props.i}</p>
              <p>{props.j}</p>
            </Card>
          </Col>
        </Row>

      {/* </section> */}
    </>
  );
};
export default PartD;
