import React from 'react';
import { Link } from 'react-router-dom';
const PrivatePolicy = () => {
    const email=': i.lubangakene1@londonmet.ac.uk';
    const mailtoLink = `mailto:${email}`;

    return (
        <>
            <section className='container' style={{border:"2px solid black",margin:"10px 20px 10px 20px"}} >
                <p>
                    “An experimental investigation on measuring hydration levels of different individuals during controlled physical exercises to facilitate in idealising a non-invasive biosensor”.
                </p>
<h3 className='mb-0'>Expert interviews</h3>
<h3 className='mt-2'>INFORMATION SHEET</h3>
<p>Thank you for considering participating in this study. This information sheet outlines the purpose of the study and provides a description of your involvement and rights as a participant, if you agree to take part.</p>
<ol style={{textAlign:"left"}}>
    <li >What is the research about?</li>
    <p>Research title: “A non-invasive sensor to measure hydration levels in different individuals.” 
I am conducting this experimental study at London Metropolitan University and this research is part of my PhD studies.–––
The research will focus on evaluating how different modes of physical exercise (strength training, running, cycling) can contribute to sweat production on different individuals. Sweat contains essential biomarkers such as electrolytes which provide important information in understanding the hydration level of the body. The technique will employ a sensor placed on the skin to extract data. Crucially, the proposed method is non-invasive and not harmful to the individuals. No blood draws will be taken from participants. 
</p>
<li >Do I have to take part?</li>
    <p>The experimental study is voluntarily. The final decision is based entirely on the individual. Upon acceptance, a consent form will be presented to the individual to sign before the experiment is conducted. </p>
<li>What will my involvement be?</li>
<p>You will be asked to engage in a simple controlled exercise. The exercise is time restricted.</p>
<li>How do I withdraw from studying?</li>
<p>You can withdraw from the study at any point until the data analysis phase starts – which is expected to be as from 01/03/2024 without having to give a reason. If any questions during the interviews/ experimental session make you feel uncomfortable, you do not have to answer them. Withdrawing from the study will have no effect on you. If you withdraw from the study, I will not retain the information you have given thus far, unless you are happy for me to do so.</p>
<li>What will my information be used for?</li>
<p>The collected information will be used for a PhD research project, for publication and when disseminating the research findings. Additionally, the data collected will be used to calibrate and optimise the sensitivity of the sensor. </p>
<li>Will my taking part and my data be kept confidential? Will it be anonymized?</li>
<p>The records from this study will be kept as confidential as possible. Access to the data will be limited to only the research team [Innocent Lubangakene (i.lubangakene1@londonmet.ac.uk), Prof.Bal Virdee (b.virdee@londonmet.ac.uk) & Renu Rajaguru (rer0266@my.londonmet.ac.uk) ] 

Data to be anonymised in compliance with GDPR regulations, your name will not be used in any reports or publications resulting from the study. The only information collected will be the dielectric readings of the sensor, age of participant, gender, weight, height, and ethnicity.  Any copies of research information will be always kept in secure digital storage or locked files. The data will be kept for a maximum of four year upon which they will be permanently and safely disposed of. 

Limits to confidentiality: At all times, confidentiality will be maintained, unless you tell me something which implies that you or someone you mention might be in significant danger of harm and unable to act for themselves; in this case, I may have to inform the relevant agencies of this, but I would discuss this with you first.
</p>
<li>Who has reviewed this study?</li>
<p>This study has undergone ethics review in accordance with the London Metropolitan University Research Ethics Policy and Procedures</p>
</ol>
<h3>CONSENT FORM - Expert interviews</h3>
<p>“An experimental investigation on measuring hydration levels of different individuals during controlled physical exercises to facilitate in idealising a non-invasive biosensor.”</p>
<ul style={{textAlign:"left"}}>
    <li>The project has been fully explained to me. 
(If you would like to answer no to this question, please do not proceed with this consent form until you are fully aware of what your participation in the project will mean.)
</li>
<li>I have been given the opportunity to ask questions about the project.</li>
<li>I agree to take part in the project.  I understand that taking part in the project will mean to be interviewed</li>
<li>I agree to the sessions being audio and video-recorded</li>
<li>I understand that my taking part is voluntary and that I can withdraw from the study until the data analysis phase starts – which is expected to be as from [01/03/2024].
I do not have to give any reasons for why I no longer want to take part and there will be no adverse consequences if I choose to withdraw.
</li>
<li>I understand my personal details such as name and email address etc. will not be revealed to people outside the project.
</li>
<li>I understand that the findings from the interviews will be published in research outputs.
</li>
<li>I understand that I will not be named in any research outputs unless I have expressly consented to be named.</li>
<li>I agree that my real name can be used for quotes in research outputs.
</li>
<li>I agree to assign the copyright I hold in any materials generated as part of this project to the Researcher, see names and contact details below.</li>
</ul>
<h4  style={{textAlign:"left"}}>Project contact details for further information:</h4>
<p style={{textAlign:"left"}}>The Researcher: <h3 style={{marginTop:"0px"}}>Innocent D. Lubangakene</h3></p>
<p style={{textAlign:"left"}}>Email:<Link to={mailtoLink} style={{fontSize:"15px"}}>i.lubangakene1@londonmet.ac.uk </Link></p>
            </section>
        </>
    )
};
export default PrivatePolicy;