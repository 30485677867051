import React, { useState } from 'react'
import PartD from '../sections/partd';
const SectionD = () => {
  const data = {
    a: "How To Maintain Hydration",
    b: "Drink plenty of water / Day",
    c: "Daily fluid intake recommendations vary by age, sex, pregnancy, and breastfeeding.",
    d: "Start by drinking a cup of water each morning when you wake up or a glass before bed. Have another glass with every meal. Drink one or two cups after working out. To ward off dehydration, drink fluids gradually throughout the day.",
    e: "Avoid Alcohol /sugar/ bevarages",
    f: "Certain drinks are dehydrating! Coffee, sugar-filled sodas, beer, wine, hard liquor, energy drinks, sweet tea, lemonade, smoothies, and flavored milk are among the beverages that are at fault.",
    g: "They contain a great deal of sodium, sugar, and other chemicals that dehydrate your tissues.",
    h: "Eat healthy and watery food",
    i: "Did you see that drinking water makes up roughly eighty percent of our daily water intake? The other twenty percent is derived from eating.",
    j: "celery, cucumbers, tomatoes, radishes, peppers, melons, cauliflower, broccoli,strawberries, and grapefruit. At least 90% of them are made of water."
  };
  return (
    <>
      <PartD {...data} />
    </>
  )
};
export default SectionD;