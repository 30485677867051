import React from 'react';
import PartC from '../sections/partc';
import img from '../../img/hydration image.png'

export default function SectionC() {
  const data={
    a:'Why Hydration Is Important',
    b:'Hydration is crucial for overall health. Water is essential for the human body, and every cell, tissue, and organ needs it to function properly.',
    c:'Water helps maintain a stable body temperature, which is vital for optimal functioning.',
    d:'Proper hydration assists in the efficient removal of waste products through urination, perspiration, and bowel movements.',
    e:' Water keeps joints lubricated, reducing the risk of discomfort and injury.',
    img:img
  }
  return (
    <>
     <PartC {...data}/>
    </>
    );
}
