import './App.css';
import { Route, Routes } from 'react-router';
import Hydration from './component/hyderation/hydration';
// import Glucose from "./glucose";
import { BrowserRouter } from 'react-router-dom';
import NavBar from './component/navbar';
import ErrorOne from './component/error/errorpage';
import PrivatePolicy from './component/privacypolicies';
import AOS from 'aos';
import 'aos/dist/aos.css'
function App() {
  return (
    <div style={{overflow:"hidden"}}>
      <NavBar/>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Hydration/>}/>
        <Route path='/hydration' element={<Hydration/>}/>
         {/* <Route path='/glucose' element={<Glucose/>}/>  */}
         <Route path='/Error' element={<ErrorOne/>}/> 

         <Route path='/privatepolicies' element={<PrivatePolicy/>}/> 
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
