import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Select, Modal } from 'antd';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from "react-js-loader";

const DataForm = ({setData}) => {
    const [status, setStatus] = useState(false);
    const [check,setCheck]=useState(false);
    const [show, setShow] = useState(false);
    const [timer, setTimer] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        showModal();
        if (!timer) {
            try {
                setData([]);
                const response = await fetch('https://lidsense.com/backend/public/api/calculate/hydration', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });
                if (response.ok) {
                    const result = await response.json();
                    setData(result.data);
                    console.log('API Response:', result);
                    // Handle success (e.g., navigate to a success page or display a success message)
                } else {
                    console.error('Error submitting form:', response.statusText);
                    // Handle error (e.g., display an error message)
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                // Handle error (e.g., display an error message)
            }
        } else {
            console.log('Checkbox is not checked');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const { Option } = Select;

    const style = {
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderRadius: 'revert',
        borderColor: 'black'
    };

    const showModal = () => {
        setShow(true);
        setTimer(true);
        setTimeout(() => {
            setStatus(true);
            setShow(false);
            setTimer(false);
        }, 10000);
    };
const HandleChange=()=>{
    setCheck(true)
}
    return (
        <>
            <Modal open={show} footer={null}>
                <div style={{display:"flex"}}>
                <h2 >Scanning  </h2>
                <Loader type="bubble-top" bgColor={'black'} color={'black'} size={50} />
                </div>
                
            </Modal>
            <div style={{ border: '2px dashed blue', borderRadius: '50px', padding: '20px' }}>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={20}>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>First Name</label>
                            <Form.Item
                                name="first_name"
                                rules={[{ required: true, message: 'Please input your first name!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>Last Name</label>
                            <Form.Item
                                name="last_name"
                                rules={[{ required: true, message: 'Please input your last name!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>Age</label>
                            <Form.Item
                                name="age"
                                rules={[{ required: true, message: 'Please input your age!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>Sex</label>
                            <Form.Item
                                name="gender"
                                rules={[{ required: true, message: 'Please select your gender!' }]}
                            >
                               <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>Blood Group</label>
                            <Form.Item
                                name="bloodgroup"
                                rules={[{ required: true, message: 'Please input your blood group!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>How Often You Sweat</label>
                            <Form.Item
                                name="drink_per_day"
                                rules={[{ required: true, message: 'Please input how often you sweat!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>Height (CM)</label>
                            <Form.Item
                                name="height"
                                rules={[{ required: true, message: 'Please input your height!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                            <label>Weight (KG)</label>
                            <Form.Item
                                name="weight"
                                rules={[{ required: true, message: 'Please input your weight!' }]}
                            >
                                <Input style={style} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="status" valuePropName="checked"
                    rules={[{ required: true, message: 'Please check!' }]}
                    >
                        <Checkbox>
                            By submitting the form, I hereby accept the
                            <Link to='/privatepolicies' onClick={showModal}>
                                Privacy Policy
                            </Link>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};
export default DataForm;
