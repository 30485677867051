export const graphData = (data) => {
  return [
    data["Severly Hydrated"] ?? 0,
    data["Moderately Hydrated"] ?? 0,
    data["Hydrated"] ?? 0,
    data["Dehydrated"] ?? 0,
    data["Moderately Dehydrated"] ?? 0,
    data["Severely Dehydrated"] ?? 0,
  ];
};
