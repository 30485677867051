import React, { useEffect, useState } from "react";
import PartB from "../sections/partb";
const SectionB = () => {
  const [chartData, setChartData] = useState([]);
  const a = "Hydration Calculator";
  const b = "Your Permitivity is ";
  const c = "79";
  const d = "Your BMI l weight should be in between";
  const e = "49kg's to 55 kg's";
  const ChartData = async () => {
    try {
      const response = await fetch(
        "https://lidsense.com/backend/public/api/calculate/hydrationGraph",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      if (response.ok) {
        const result = await response.json();
        setChartData(result.data);
        // Handle success (e.g., navigate to a success page or display a success message)
      } else {
        console.error("Error submitting form:", response.statusText);
        // Handle error (e.g., display an error message)
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., display an error message)
    }
  };
  useEffect(() => {
    ChartData();
  }, []);
  return (
    <>
      <PartB
        data1={a}
        data2={b}
        data3={c}
        data4={d}
        data5={e}
        chartData={chartData}
      />
    </>
  );
};
export default SectionB;
