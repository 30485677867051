import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { graphData } from "../../Utils";

const Donut = () => {
  const [categories, setCategories] = useState([]);
  const ChartData = async () => {
    try {
      const response = await fetch(
        "https://lidsense.com/backend/public/api/calculate/hydrationGraph",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      if (response.ok) {
        const result = await response.json();
        const data = result.data;
        const d = graphData(data);
        setCategories(d);
        // Handle success (e.g., navigate to a success page or display a success message)
      } else {
        console.error("Error submitting form:", response.statusText);
        // Handle error (e.g., display an error message)
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., display an error message)
    }
  };
  useEffect(() => {
    ChartData();
  }, []);
  const options = {
    chart: {
      type: "bar",
      height: 200,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: [
          "#0a2aee",
          "#2540ea",
          "#435aea",
          "#566aea",
          "#7081ed",
          "#8f9cf0",
        ],
      },
    },
    xaxis: {
      categories: [
        "Severly Hydrated",
        "Moderately Hydrated",
        "Hydrated",
        "Dehydrated",
        "Moderately Dehydrated",
        "Severely Dehydrated",
      ],
    },
  };

  const series = [
    {
      data: categories,
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="bar" height={250} />
  );
};

export default Donut;
