import React, { useEffect, useState } from "react";
import DataForm from "../form";
import { Card, Col, Row } from "antd";
import ScrollAnimation from "react-animate-on-scroll";
import Donut from "../chart";
import Aos from "aos";

const PartB = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <>
      {/* <section className='container'> */}
      <h1 style={{ textAlign: "center", paddingBottom: "15px", width: "100%" }}>
        {props.data1}
      </h1>
      {/* {console.log("data",props.data1)} */}
      <Row gutter={20}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xxl={12}
          xl={12}
          style={{ marginBottom: "20px" }}
          data-aos="fade-right"
        >
          <Row gutter={16}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ paddingBottom: "20px" }}
            >
              <Card
                style={{ borderRadius: "15px", backgroundColor: "aliceblue" }}
              >
                <Donut chartData={props.chartData} />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              <Card
                style={{
                  borderColor: "blue",
                  borderRadius: "50px",
                  backgroundColor: "aliceblue",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <h4 style={{ marginBottom: "0px" }}>{props.data2}</h4>
                    <h2
                      style={{
                        color: "blue",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      {data.average_return_loss
                        ? parseFloat(data?.average_return_loss).toFixed(2)
                        : "0.00"}
                    </h2>
                  </div>
                  <hr
                    type="vertical"
                    style={{ color: "blue", margin: "10px" }}
                  ></hr>
                  <div>
                    <h4>Hydration Status</h4>
                    <h2
                      style={{
                        color: "blue",
                        fontWeight: "500",
                        marginTop: "10px",
                      }}
                    >
                      {data?.hydration_status}
                    </h2>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xxl={12}
          xl={12}
          style={{ marginBottom: "20px" }}
          data-aos="fade-left"
        >
          <DataForm setData={setData} />
        </Col>
      </Row>
      {/* </section> */}
    </>
  );
};
export default PartB;
